const gameLibrary = {
  "2022-03-21" : "Max Payne 2",
  "2022-03-22" : "Dark Souls",
  "2022-03-23" : "Kirby's Dream Land",
  "2022-03-24" : "Counter Strike Source",
  "2022-03-25" : "The Darkness 2",
  "2022-03-26" : "Eternal Darkness",
  "2022-03-27" : "Devil May Cry 3",
  "2022-03-28" : "The King of Fighters XI",
  "2022-03-29" : "Fatal Frame 2",
  "2022-03-30" : "Dragon Ball Z: Budokai Tenkaichi",
  "2022-03-31" : "Call of Duty 4 Modern Warfare",
  "2022-04-01" : "Bloodborne",
  "2022-04-02" : "Batman Arkham Asylum",
  "2022-04-03" : "Metal Gear Solid 3: Snake Eater",
  "2022-04-04" : "Left 4 dead",
  "2022-04-05" : "Spongebob squarepants: lights camera pants",
  "2022-04-06" : "Spec Ops The Line",
  "2022-04-07" : "Fallout New Vegas",
  "2022-04-08" : "Halo 3 ODST",
  "2022-04-09" : "splinter cell chaos theory",
  "2022-04-10" : "E.T.",
  "2022-04-11" : "Thief 2 The Metal Age",
  "2022-04-12" : "Touhou Koumakyou",
  "2022-04-13" : "50 cent blood on the sand",
  "2022-04-14" : "sopranos road to respect",
  "2022-04-15" : "lord of the rings battle for middle earth II",
  "2022-04-16" : "Dragon Age Origins",
  "2022-04-17" : "Risk Of Rain 2",
  "2022-04-18" : "Dead Island",
  "2022-04-19" : "Killing Floor",
  "2022-04-20" : "Maplestory",
  "2022-04-21" : "Resident Evil 5",
  "2022-04-22" : "Mortal Kombat Deadly Alliance",
  "2022-04-23" : "Half Life",
  "2022-04-24" : "Deus Ex",
}



// "2022-03-21": `Black & White 2`,
// "2022-03-22": `Darwinia`,
// Metal Gear Solid 2

export default gameLibrary